import DOMPurify from "dompurify";
import AuthService from "../../services/AuthServices";
import { message } from "antd";
import {
  Auth_Confirm,
  EmailPut,
  Errors,
  IsLoading,
  IsModal,
  LoadButton,
  Phone_Num,
  Token,
  TokenUrl,
  SetAcronym,
  SetVerifyToken,
  SetStatusCode,
  SetUserBlock,
  SetUserBlockData,
  SetTelegram,
  User_Email,
  NewUser,
  User_Phone,
} from "../slices/userSlice";
import { emailPattern, errorsKey } from "../../utils";
import {
  IUser,
  ILogin,
  IConfirm,
  IRecoveryAccess,
  ISaveNewPassword,
  IOnceFuncArguments,
  IRepeatFuncAction,
  IError,
  IEsiaLoginRequest,
} from "../../types/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ErrorIcon } from "../../assets/icons/ErrorIcon";
import { SuccessIcon } from "../../assets/icons/SuccessIcon";
import { WarningIcon } from "../../assets/icons/WarningIcon";

const secureURL = (url: string): string | null => {
  const baseDomain = process.env.REACT_APP_UNIONE_BASE_URL?.replaceAll(
    "https://",
    ""
  ).replaceAll("http://", "");

  // Начинается ли URL с https
  if (!url?.startsWith("https")) {
    return null;
  }

  // Содержит ли URL доменное имя
  if (baseDomain && !url?.includes(baseDomain)) {
    return null;
  }

  // Декодирование URL и очистка от опасных символов
  if (url) {
    url = decodeURIComponent(url);
    url = DOMPurify.sanitize(url); // Очистка URL от опасных символов (XSS)
  }

  return url;
};

// Редирект
const redirect = (url?: string) => {
  const baseDomain = process.env.REACT_APP_UNIONE_BASE_URL || "";
  if (!url?.length) {
    window.location.href = baseDomain;

    return;
  }

  const toURL = secureURL(url);

  window.location.href = toURL || baseDomain;
};

// авторизация
export const _login = createAsyncThunk(
  "userSlice/_login",
  async (
    { phone = "", email = "", password, acronym }: ILogin,
    { dispatch, getState }
  ) => {
    const contactInfo = email ? { email } : { phone };
    const data = JSON.stringify({
      ...contactInfo,
      password,
      acronym,
    });
    const response = await AuthService.$auth(data);

    if (response.data.status) {
      const { userSlice } = getState() as IUser;
      const token = response.data.values[0];
      const tokenS3 = response.data.values[1];

      localStorage.setItem("tokenAuth", token);
      localStorage.setItem("tokenS3", tokenS3);
      dispatch(Token(token));

      message.success({
        className: "messageSuccess",
        content: " Вы авторизованы ",
        icon: <SuccessIcon />,
      });

      if (response.data.need_fields) {
        redirect(userSlice._url.redirect);
      } else {
        redirect(
          `${process.env.REACT_APP_ONEID_BASE_URL}/${userSlice._params_send}`
        );
      }
    } else {
      const err = response.data.errors[0].key;
      const err_text = errorsKey(err);
      dispatch(Errors(err_text));
      dispatch(IsLoading(false));
    }
  }
);

//переход на codeRequest и запрос пароля и подтверждение
export const onceFunc = createAsyncThunk(
  "userSlice/onceFunc",
  async (
    {
      phone = "",
      email = "",
      acronym,
      verify_token,
      setVisible,
    }: IOnceFuncArguments,
    { dispatch }
  ) => {
    dispatch(LoadButton(true));
    dispatch(Phone_Num(phone));
    dispatch(User_Email(email));
    dispatch(User_Phone(phone));
    const contactInfo = email ? { email } : { phone };
    const response = await AuthService.$getting_key(
      contactInfo,
      "",
      verify_token
    );

    if (response.data.status) {
      if (response.data.values[0].sended_to === "telegram") {
        message.success({
          className: "messageSuccess",
          content: "Код подтверждения выслан на ваш telegram",
          icon: <SuccessIcon />,
        });
        dispatch(SetTelegram(response.data.values[0].sended_to));
      } else if (emailPattern.test(response.data.values[0].sended_to)) {
        message.success({
          className: "messageSuccess",
          content: " Код подтверждения выслан на ваш email ",
          icon: <SuccessIcon />,
        });
        if (!response.data.values[0].phone) {
          dispatch(NewUser(true));
        }
        dispatch(EmailPut(response.data.values[0].sended_to));
      } else if (response.data.values[0].phone) {
        message.success({
          className: "messageSuccess",
          content: "Код подтверждения выслан на ваш номер",
          icon: <SuccessIcon />,
        });
      }
      dispatch(Auth_Confirm(true));
      dispatch(SetAcronym(acronym));
      dispatch(SetVerifyToken(verify_token));
      message.warning({
        className: "messageWarning",
        content: "Введите код доступа!",
        icon: <WarningIcon />,
      });
    } else {
      if (
        response.data.errors[0].key === 97 ||
        response.data.errors[0].key === 98
      ) {
        const error_msg = JSON.parse(response.data.errors[0].message);
        dispatch(SetUserBlock(true));
        dispatch(SetUserBlockData(error_msg.message));
      } else {
        const err = response.data.errors[0].key;
        const err_text = errorsKey(err);
        dispatch(Errors(err_text));
      }
    }
    setVisible(false);
    dispatch(LoadButton(false));
  }
);

// выслать код повторно
export const repeatFunc = createAsyncThunk(
  "userSlice/repeatFunc",
  async (
    { phone = "", email = "", send_to, verify_token }: IRepeatFuncAction,
    { dispatch }
  ) => {
    dispatch(LoadButton(true));
    const contactInfo = email ? { email } : { phone };
    const response = await AuthService.$getting_key(
      contactInfo,
      send_to,
      verify_token
    );
    if (response.data.status) {
      if (emailPattern.test(response.data.values[0].sended_to)) {
        message.success({
          className: "messageSuccess",
          content: " Код подтверждения выслан на ваш email ",
          icon: <SuccessIcon />,
        });
        dispatch(EmailPut(response.data.values[0].sended_to));
      } else {
        message.success({
          className: "messageSuccess",
          content: "Код успешно выслан!",
          icon: <SuccessIcon />,
        });
        dispatch(EmailPut(""));
        dispatch(SetTelegram(""));
        dispatch(Phone_Num(response.data.values[0].sended_to));
      }
    } else {
      const err = response.data.errors[0].key;
      const err_text = errorsKey(err);
      dispatch(Errors(err_text));
    }
    dispatch(LoadButton(false));
  }
);

// получение кода и проверка
export const _confirm_code = createAsyncThunk(
  "userSlice/_confirm_code",
  async (
    { phone, code, email, _userEmail, telegram }: IConfirm,
    { dispatch, getState }
  ) => {
    const contactInfo = _userEmail ? { email: _userEmail } : { phone: phone };
    let value;

    if (email) {
      value = JSON.stringify({
        ...contactInfo,
        sended_to: "email",
        code: code,
      });
    } else if (telegram) {
      value = JSON.stringify({
        ...contactInfo,
        sended_to: "telegram",
        code: code,
      });
    } else {
      value = JSON.stringify({
        ...contactInfo,
        sended_to: "phone",
        code: code,
      });
    }

    dispatch(IsLoading(true));
    const response = await AuthService.$confirmCode(value);
    if (response.data.status) {
      const { userSlice } = getState() as IUser;
      const token = response.data.values[0];
      const tokenS3 = response.data.values[1];
      const needFields = response.data.need_fields;

      localStorage.setItem("tokenAuth", token);
      localStorage.setItem("tokenS3", tokenS3);
      await window.unione.setUnioneToken(token);
      await window.unione.setS3Token(tokenS3);

      dispatch(Token(token));

      message.success({
        className: "messageSuccess",
        content: "Вы авторизованы",
        icon: <SuccessIcon />,
      });

      if (needFields) {
        redirect(userSlice._url.redirect);
      } else {
        redirect(
          `${process.env.REACT_APP_ONEID_BASE_URL}/${userSlice._params_send}`
        );
      }
    } else {
      response.data.errors.forEach((error: IError) => {
        if (error.key === 97 || error.key === 98) {
          const error_msg = JSON.parse(error.message);
          dispatch(SetUserBlock(true));
          dispatch(SetUserBlockData(error_msg.message));
        } else {
          const err = error.key;
          const err_text = errorsKey(err);
          dispatch(Errors(err_text));
          message.error({
            className: "messageError",
            content: err_text,
            icon: <ErrorIcon />,
          });
        }
      });
      dispatch(IsLoading(false));
    }
  }
);

// Получения данных пользователя
export const get_user_actions = createAsyncThunk(
  "userSlice/get_user_actions",
  async (token: string, { dispatch, getState }) => {
    dispatch(IsLoading(true));

    const value = JSON.stringify({ token: token });
    const response = await AuthService.$get_user(value);

    if (response.status >= 500) {
      dispatch(SetStatusCode(true));
    }

    if (response.data.status) {
      const { userSlice } = getState() as IUser;
      const params = userSlice._params_send;

      localStorage.setItem("tokenAuth", token);

      dispatch(getSaltedToken(token));
      dispatch(IsModal(true));

      if (params.includes("&state=")) {
        redirect(`${process.env.REACT_APP_ONEID_BASE_URL}/${params}`);

        return;
      }

      setTimeout(() => redirect(userSlice._url.redirect), 2000);
    } else {
      dispatch(IsLoading(false));
    }
  }
);

// Выход из аккаунта
export const logout = createAsyncThunk(
  "userSlice/logout",
  async (token: string, { dispatch, getState }) => {
    dispatch(IsLoading(true));

    const value = JSON.stringify({ token: token });
    const response = await AuthService.$logout(value);

    if (response.data.status) {
      const { userSlice } = getState() as IUser;

      localStorage.removeItem("_utid");
      localStorage.removeItem("tokenAuth");

      message.info({
        className: "messageInfo",
        content: "Вы вышли из аккаунта",
        icon: <WarningIcon />,
      });

      setTimeout(() => {
        redirect(userSlice._url.redirect);
      }, 2000);
    } else {
      redirect();
    }
  }
);

// восстановления доступа
export const _recovery_access = createAsyncThunk(
  "userSlice/_recovery_access",
  async (
    {
      phone,
      setIsModalSendSuccess,
      setIsForgotPasswordModalVisible,
      setIsModalSendError,
    }: IRecoveryAccess,
    { dispatch }
  ) => {
    const value = JSON.stringify({ phone: phone });
    const response = await AuthService.$recover_access(value);
    setIsForgotPasswordModalVisible(false);
    if (response.data.status) {
      const email = response.data.values[0];
      dispatch(EmailPut(email));
      setIsModalSendSuccess(true);
    } else {
      setIsModalSendError(true);
    }
  }
);

// Проверка корректности ссылки
export const check_recovery_token = createAsyncThunk(
  "userSlice/check_recovery_token",
  async (token_url: string, { dispatch }) => {
    dispatch(IsLoading(true));

    const value = JSON.stringify({ token: token_url });
    const response = await AuthService.$recovery_check(value);

    if (response.data.status) {
      dispatch(IsLoading(false));
      dispatch(TokenUrl(token_url));
    } else {
      const err = response.data.errors[0].key;
      const err_text = errorsKey(err);
      message.error({
        className: "messageError",
        content: err_text,
        icon: <ErrorIcon />,
      });
      setTimeout(() => redirect(process.env.REACT_APP_AUTH_BASE_URL), 1000);
    }
  }
);

// Сохранение нового пароля при восстановлении
export const save_new_password = createAsyncThunk(
  "userSlice/save_new_password",
  async ({ _token_url, password }: ISaveNewPassword) => {
    const value = JSON.stringify({
      token: _token_url,
      "new-password": password,
    });
    const response = await AuthService.$recovery_save(value);

    if (response.data.status) {
      message.success({
        className: "messageSuccess",
        content: "Вы успешно изменили пароль",
        icon: <SuccessIcon />,
      });

      setTimeout(() => redirect(process.env.REACT_APP_AUTH_BASE_URL), 1000);
    } else {
      const err = response.data.errors[0].key;
      const err_text = errorsKey(err);
      message.error({
        className: "messageError",
        content: err_text,
        icon: <ErrorIcon />,
      });
    }
  }
);

export const loginWithLeaderId = createAsyncThunk(
  "userSlice/loginWithLeaderId",
  async (_, { getState }) => {
    const { userSlice } = getState() as IUser;
    let url = DOMPurify.sanitize(userSlice._url.redirect);
    const value = JSON.stringify({ redirect_url: url });
    const response = await AuthService.$auth_with_leader_id(value);

    if (response.data.status) {
      const redirectURL = DOMPurify.sanitize(response.data.values[0]);
      window.location.href = redirectURL;
    } else {
      const err = response.data.errors[0].key;
      const err_text = errorsKey(err);
      message.error({
        className: "messageError",
        content: err_text,
        icon: <ErrorIcon />,
      });
    }
  }
);

// Получение ссылки на вход в есиа
export const getEsiaLoginLink = createAsyncThunk(
  "userSlice/getEsiaLoginLink",
  async () => {
    const response = await AuthService.$get_esia_login_link();

    if (response?.data.status) {
      const redirectURL = DOMPurify.sanitize(response.data.values[0]);
      window.location.href = redirectURL;
    } else {
      const err = response.data.errors[0].key;
      const err_text = errorsKey(err);
      message.error({
        className: "messageError",
        content: err_text,
        icon: <ErrorIcon />,
      });
    }
  }
);

export const esiaLogin = createAsyncThunk(
  "userSlice/esiaLogin",
  async (data: IEsiaLoginRequest, { dispatch, getState }) => {
    const value = JSON.stringify(data);
    const response = await AuthService.$esia_login(value);

    if (response?.data.status) {
      const { userSlice } = getState() as IUser;
      const token = response.data.values[0];

      localStorage.setItem("tokenAuth", token);
      await window.unione.setUnioneToken(token);
      dispatch(Token(token));

      message.success({
        className: "messageSuccess",
        content: "Вы авторизованы",
        icon: <SuccessIcon />,
      });

      if (response.data.need_fields) {
        redirect(userSlice._url.redirect);
      } else {
        redirect(process.env.REACT_APP_ONEID_BASE_URL);
      }
    } else {
      const err = response.data.errors[0].key;
      const err_text = errorsKey(err);
      if (err === 273) {
        message.success({
          className: "messageError",
          content: err_text,
          icon: <ErrorIcon />,
        });
      } else {
        message.error({
          className: "messageError",
          content: err_text,
          icon: <ErrorIcon />,
        });
      }
    }
  }
);

// Получение соленого токена из обычного токена
export const getSaltedToken = createAsyncThunk(
  "userSlice/getSaltedToken",
  async (token: string) => {
    const value = JSON.stringify({ token: token });
    const response = await AuthService.$get_salted_token(value);

    if (response?.data.status) {
      const tokenS3 = response.data.values[0];
      localStorage.setItem("tokenS3", tokenS3);
      await window.unione.setS3Token(tokenS3);
    } else {
      const err = response.data.errors[0].key;
      const err_text = errorsKey(err);
      message.error({
        className: "messageError",
        content: err_text,
        icon: <ErrorIcon />,
      });
    }
  }
);
